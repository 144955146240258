<template>
  <v-col class="pa-0">
    <v-expansion-panels v-model="isPanelOpen" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header class="py-4 pr-3 pl-6 ma-0 black white--text">
          <div class="d-flex justify-space-between align-center">
            <v-card-title class="pa-0 font-weight-bold">
              {{ $t('campaign.groups') }}
            </v-card-title>

            <v-spacer />

            <v-btn
              color="primary"
              depressed
              class="mr-3 text-none"
              @click.native.stop="createGroupDialog()"
            >
              <v-icon :left="!isMobile" size="14">
                $plus
              </v-icon>
              <span class="d-none d-sm-flex">{{ $t('campaign.create_group') }}</span>
            </v-btn>
          </div>
          <template #actions>
            <v-icon color="white" :size="16">
              $arrow-down
            </v-icon>
          </template>
        </v-expansion-panel-header>

        <v-expansion-panel-content class="pa-0 ma-0">
          <div class="pa-0 ma-0">
            <v-data-table
              :headers="headersComputed"
              :items="items"
              :options.sync="options"
              :loading="isLoading"
              :mobile-breakpoint="0"
              :server-items-length="total"
              hide-default-footer
              :hide-default-header="isMobile"
              @page-count="getPageCount($event)"
              @update:options="setOptions($event)"
            >
              <template v-if="isMobile" #item="{ item, isSelected }">
                <mobile-row-for-groups
                  :item="item"
                  :selected="isSelected"
                  @archive="archiveGroupDialog($event)"
                  @edit="editGroupDialog($event)"
                  @click="setCampaignGroupId($event)"
                />
              </template>
              <template #item.id="{ item }">
                <p class="secondary-darken--text mb-0">
                  {{ item.id }}
                </p>
              </template>
              <template #item.name="{ item }">
                <a class="black--text" @click="setCampaignGroupId(item.id)">{{ item.name }}</a>
              </template>

              <template #item.actions="{ item }">
                <campaign-group-actions
                  @archive="archiveGroupDialog(item)"
                  @edit="editGroupDialog(item)"
                />
              </template>
              <template #footer>
                <data-table-footer
                  :items-per-page="options.itemsPerPage"
                  :current-page="options.page"
                  :page-count="options.pageCount"
                  @change-items-per-page="changeItemsPerPage($event)"
                  @change-current-page="changeCurrentPage($event)"
                />
              </template>
            </v-data-table>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <campaign-dialog
      v-if="dialogIsShown"
      :campaign-config-form="campaignConfigForm"
      @close-dialog="dialogIsShown = false"
      @create-group="createGroupEmit()"
      @edit-group="editGroupEmit()"
      @archive-group="archiveGroupEmit()"
    >
      <template v-if="campaignConfigForm.textField">
        <v-text-field
          v-model="campaignConfigForm.payload.name"
          :label="$t('main.name')"
          :error-messages="errors.name"
          color="primary"
        />
      </template>

      <template v-else>
        {{ $t(campaignConfigForm.message) }}
      </template>
    </campaign-dialog>
  </v-col>
</template>

<script>
  import DataTableFooter from '@clickadilla/components/ui/DataTableFooter.vue'
  import { mapActions } from 'vuex'
  import CampaignGroupActions from '@/components/Campaign/CampaignGroupActions.vue'
  import campaignGroupsRepository from '@/services/repositories/campaign-groups-repository.js'
  import ExceptionError from '@/services/classes/ExceptionError.js'
  import MobileRowForGroups from '@/views/Campaigns/CampaignGroupsTable/MobileRowForGroups.vue'
  import CampaignDialog from '@/components/Campaign/CampaignDialog.vue'

  export default {
    name: 'CampaignGroupsTable',
    components: {
      CampaignGroupActions,
      DataTableFooter,
      MobileRowForGroups,
      CampaignDialog
    },
    data() {
      return {
        isPanelOpen: [],
        selectedCampaigns: [],
        items: [],
        options: {
          sortBy: [],
          sortDesc: [true],
          page: 1,
          itemsPerPage: 15,
          pageCount: 0
        },
        isLoading: false,
        total: 0,
        dialogIsShown: false,
        campaignConfigForm: {},
        errors: {}
      }
    },
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      headersComputed: {
        get() {
          return [
            {
              value: 'actions',
              text: this.$t('main.actions'),
              width: '140px',
              sortable: false,
              class: 'black--text'
            },
            {
              value: 'id',
              text: 'ID',
              width: '140px',
              class: 'black--text'
            },
            {
              value: 'name',
              text: this.$t('main.name'),
              width: '600px',
              class: 'black--text'
            }
          ]
        },
        set(value) {
          return [...value]
        }
      }
    },
    methods: {
      ...mapActions('campaigns', ['fetchCampaignGroups', 'setCampaignGroupId']),
      createGroupEmit() {
        this.createGroup(this.campaignConfigForm.payload)
      },
      editGroupEmit() {
        this.editGroup(this.campaignConfigForm.payload)
        this.dialogIsShown = false
      },
      archiveGroupEmit() {
        this.archiveGroup(this.campaignConfigForm.payload)
        this.dialogIsShown = false
      },
      clearErrors() {
        this.errors = {}
      },
      createGroupDialog() {
        this.clearErrors()
        this.dialogIsShown = true
        this.campaignConfigForm = {
          title: 'campaign.create_group',
          textField: true,
          payload: { name: '' },
          eventName: 'create-group'
        }
      },
      editGroupDialog(campaignGroup) {
        this.dialogIsShown = true
        this.campaignConfigForm = {
          title: 'campaign.edit_group',
          textField: true,
          payload: campaignGroup,
          eventName: 'edit-group'
        }
      },
      archiveGroupDialog(campaignGroup) {
        this.dialogIsShown = true
        this.campaignConfigForm = {
          title: 'campaign.archive_group',
          message: 'campaign.archive_group_campaign_msg',
          payload: campaignGroup,
          eventName: 'archive-group'
        }
      },
      async fetchTableCampaignGroups() {
        try {
          this.isLoading = true
          const defaultSort = this.options.sortBy || ['id']

          const requestBody = {
            headers: this.headers,
            options: { ...this.options, sortBy: defaultSort },
            draw: this.options.page
          }

          const response = await campaignGroupsRepository.table(requestBody)
          await this.fetchCampaignGroups()
          this.items = response.items
          this.total = response.totalItemsCount
        } finally {
          this.isLoading = false
        }
      },
      async fetchAction(actionType, item) {
        try {
          this.isLoading = true

          await campaignGroupsRepository[actionType](item)
          await this.fetchTableCampaignGroups()

          this.$showSuccessNotification(this.$t(`campaign.campaign_group_successfully.${actionType}`))

          this.isPanelOpen = [0]
          this.dialogIsShown = false
        } catch (error) {
          if (error.response.data.message) {
            this.errors = error.response.data.errors
          }
          if (error instanceof ExceptionError) {
            this.$showErrorNotification(error.message)
          }

          this.isLoading = false
        }
      },
      createGroup({ name }) {
        this.fetchAction('store', name)
      },
      archiveGroup({ id }) {
        this.fetchAction('delete', id)
      },
      editGroup(item) {
        this.fetchAction('edit', item)
      },
      async setOptions(options) {
        this.options = { ...this.options, ...options }
        this.fetchTableCampaignGroups()
      },
      getPageCount(event) {
        this.options.pageCount = event
      },
      changeCurrentPage(event) {
        this.options.page = event
      },
      changeItemsPerPage(event) {
        this.options.itemsPerPage = event
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .v-expansion-panel::before {
    box-shadow: none !important;
  }

  ::v-deep .v-expansion-panel-content__wrap {
    padding: 0;
  }
</style>
