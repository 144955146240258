<template>
  <v-footer fixed height="60" class="elevation-12 d-flex justify-center pa-2 primary">
    <v-tooltip v-for="action in actions" :key="action.icon" top>
      <template #activator="{ on }">
        <c-btn
          icon
          class="mx-2"
          :color="action.color"
          :disabled="action.disabled"
          :icon-props="{
            icon: action.icon
          }"
          @click="clickHandler(action)"
          v-on="on"
        />
      </template>
      <span>{{ action.title }}</span>
    </v-tooltip>
  </v-footer>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'

  export default {
    name: 'MultipleActions',
    components: {
      CBtn
    },
    props: {
      campaigns: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      actions() {
        return [
          {
            color: 'white',
            icon: 'mdi-play',
            disabled: this.campaigns.some((campaign) => !campaign.actions.activable),
            event: 'activate',
            confirm: this.$t('main.multiple_actions.confirm_activate_campaign', {
              campaignsCount: this.campaigns.length
            }),
            title: this.$t('main.multiple_actions.activate_campaign')
          },
          {
            color: 'white',
            icon: 'mdi-pause',
            disabled: this.campaigns.some((campaign) => !campaign.actions.pausable),
            event: 'pause',
            confirm: this.$t('main.multiple_actions.confirm_pause_campaign', {
              campaignsCount: this.campaigns.length
            }),
            title: this.$t('main.multiple_actions.pause_campaign')
          },
          {
            color: 'error',
            icon: 'mdi-delete-outline',
            disabled: this.campaigns.some((campaign) => !campaign.actions.deletable),
            event: 'archive',
            confirm: this.$t('main.multiple_actions.confirm_archive_campaign', {
              campaignsCount: this.campaigns.length
            }),
            title: this.$t('main.multiple_actions.archive_campaign')
          },
          {
            color: 'white',
            icon: '$close',
            disabled: false,
            event: 'clear',
            title: this.$t('main.multiple_actions.clear_selected', {
              campaignsCount: this.campaigns.length
            })
          },
          {
            color: 'white',
            icon: 'mdi-folder-move',
            disabled: false,
            event: 'set-group',
            title: this.$t('main.multiple_actions.set_group')
          },
          {
            color: 'white',
            icon: 'mdi-file-multiple',
            disabled: this.campaigns.length > 1,
            event: 'duplicate-campaign',
            title: this.$t('main.multiple_actions.duplicate_campaign')
          }
        ]
      }
    },
    methods: {
      clickHandler(action) {
        this.$emit(action.event)
      }
    }
  }
</script>
