<template>
  <div
    class="d-flex flex-wrap rounded rounded-b-0 overflow-hidden justify-space-between black pa-4"
  >
    <div class="text-h6 mr-1 font-weight-bold white--text">
      {{ $t('main.campaigns') }}
    </div>
    <v-btn
      depressed
      small
      height="32"
      color="primary"
      class="text-none text-body-2 mr-1"
      @click="createCampaign()"
    >
      <span class="d-flex font-weight-regular">{{ $t('main.create_campaign') }}</span>
    </v-btn>
  </div>
</template>

<script>
  import { GTM_EVENTS } from '@clickadilla/components/constants/gtm-events.js'
  import routeNames from '@/types/route-names.js'
  import gtmPush from '@/services/utils/gtm-push.js'

  export default {
    name: 'CampaignHeader',
    methods: {
      createCampaign() {
        this.$router.push({ name: routeNames.CREATE_CAMPAIGN })
        gtmPush({
          event: GTM_EVENTS.CAMPAIGNS_ACTION_CREATE_CAMPAIGN
        })
      }
    }
  }
</script>
