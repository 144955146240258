<template>
  <tr class="mobile-row-tr">
    <td class="pb-1 px-0">
      <div class="campaign-card pa-4 rounded global-full-width">
        <div class="d-flex align-center justify-space-between secondary-lighten  rounded pa-3">
          <v-checkbox v-model="isSelected" class="ma-0 pa-0" hide-details color="secondary-darken" />
          <actions
            :campaign="item"
            @edit="$emit('edit')"
            @activate="$emit('activate')"
            @pause="$emit('pause')"
            @archive="$emit('archive')"
            @duplicate="$emit('duplicate')"
            @statistics="$emit('statistics')"
          />
        </div>
        <v-row class="mb-4 mt-4 px-4" wrap>
          <v-col cols="6" class="pa-0">
            <div class="black--text font-weight-bold text-caption">
              {{ $t('main.name') }}
            </div>
            <div class="d-flex flex-column align-start">
              <span class="secondary-darken--text text-subtitle-2">
                #{{ item.id }}
              </span>
              <div class="d-flex">
                <router-link class="campaign-name global-full-width d-block primary--text  text-subtitle-2 " :to="{ name: routeNames.CAMPAIGN, params: { id: item.id } }">
                  <span class="global-line-clamp"> {{ item.name }}</span>
                </router-link>
                <div class="d-flex align-center ml-2">
                  <campaign-group-tooltip v-if="item.campaign_group && item.campaign_group.name" :campaign="item" />
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="6" class="pa-0">
            <div class="black--text font-weight-bold text-caption">
              {{ $t('main.status') }}
            </div>
            <campaign-new-status :campaign="item" />
          </v-col>
        </v-row>

        <v-row class="mb-4 mt-4 px-4" wrap>
          <v-col cols="6" class="pa-0">
            <div class="black--text font-weight-bold text-caption">
              {{ $t('main.ad_format') }}
            </div>
            <span class="secondary-darken--text ">
              {{ $t(`main.ad_format_types.${item.ad.ad_format_type}`) }}
            </span>
          </v-col>
          <v-col cols="6" class="pa-0">
            <div class="black--text font-weight-bold text-caption">
              {{ $t('main.price') }}
            </div>
            <span class="secondary-darken--text ">
              $ {{ item.price }}
            </span>
          </v-col>
        </v-row>

        <v-row class="mb-4 mt-4 px-4" wrap>
          <v-col cols="6" class="pa-0">
            <div class="black--text font-weight-bold text-caption">
              {{ $t('campaign.pricing_model') }}
            </div>
            <custom-chip-status
              small
              label
              outlined
              :value="item.pricing_model"
              :settings="pricingModels"
            />
          </v-col>
          <v-col cols="6" class="pa-0">
            <div class="black--text font-weight-bold text-caption">
              {{ $t('main.approval_status') }}
            </div>
            <custom-chip-status
              small
              label
              :value="item.latest_approval.status"
              :settings="approvalStatusTypes"
            />
          </v-col>
        </v-row>
      </div>
    </td>
  </tr>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import Actions from '@/components/CampaignActions.vue'
  import CustomChipStatus from '@/components/CustomChipStatus.vue'
  import CampaignGroupTooltip from '@/components/Campaign/CampaignGroupTooltip.vue'
  import CampaignNewStatus from '@/components/Campaign/CampaignNewStatus.vue'
  import routeNames from '@/types/route-names.js'

  export default {
    name: 'MobileRow',
    components: {
      CampaignGroupTooltip,
      CustomChipStatus,
      CampaignNewStatus,
      Actions
    },
    props: {
      selected: Boolean,
      item: {
        type: Object,
        default: () => ({})
      }
    },
    computed: {
      routeNames() {
        return routeNames
      },
      ...mapState('campaigns', ['pricingModels']),
      ...mapGetters('approvables', ['approvalStatusTypes']),
      isSelected: {
        get() {
          return this.selected
        },
        set(value) {
          this.$emit('select', value)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .campaign-card {
    border: 1px solid var(--v-secondary-base);
  }
  .campaign-name {
    max-width: 128px;
  }
  .mobile-row-tr > td{
    border-bottom: none !important;
  }
</style>
