<template>
  <tr>
    <td>
      <v-row class="mt-3 mb-3" wrap>
        <v-col cols="4">
          <div class="text-subtitle-2">
            ID
          </div>
          {{ item.id }}
        </v-col>
        <v-col cols="5">
          <div class="text-subtitle-2">
            {{ $t('main.name') }}
          </div>

          <a @click="itemIdHandler(item.id)">{{ item.name }}</a>
        </v-col>
        <v-col cols="2">
          <campaign-group-actions @archive="$emit('archive', item)" @edit="$emit('edit', item)" />
        </v-col>
      </v-row>
    </td>
  </tr>
</template>

<script>
  import CampaignGroupActions from '@/components/Campaign/CampaignGroupActions.vue'

  export default {
    name: 'MobileRowForGroups',
    components: {
      CampaignGroupActions
    },
    props: {
      item: {
        type: Object,
        default: () => ({})
      }
    },
    methods: {
      itemIdHandler(id) {
        this.$emit('click', id)
      }
    }
  }
</script>
