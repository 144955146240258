<template>
  <div>
    <v-layout>
      <v-tooltip v-for="action in actions" :key="action.condition" bottom>
        <template #activator="{ on }">
          <c-btn
            icon
            x-small
            class="mx-1"
            :color="action.color"
            :icon-props="{
              icon: action.icon,
              size: 12
            }"
            @click="actionHandler(action)"
            v-on="on"
          />
        </template>
        <span>{{ action.title }}</span>
      </v-tooltip>
    </v-layout>
  </div>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'

  export default {
    name: 'CampaignGroupActions',
    components: {
      CBtn
    },
    computed: {
      actions() {
        return [
          {
            condition: 'editable',
            color: 'black',
            eventName: 'edit',
            icon: '$pencil',
            title: this.$t('main.campaign_actions.edit_campaign')
          },
          {
            condition: 'deletable',
            color: 'error',
            eventName: 'archive',
            icon: '$trash',
            title: this.$t('main.campaign_actions.archive_campaign')
          }
        ]
      }
    },
    methods: {
      actionHandler(action) {
        this.$emit(action.eventName)
      }
    }
  }
</script>
