<template>
  <div>
    <v-layout>
      <div v-for="(action, index) in actions" :key="index" class="action">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <c-btn
              icon
              x-small
              class="mx-2"
              :disabled="action.disabled"
              :color="action.color"
              :icon-props="{
                icon: action.icon,
                size: 12
              }"
              @click="act(action)"
              v-on="on"
            />
          </template>
          <span>{{ action.title }}</span>
        </v-tooltip>
      </div>
    </v-layout>

    <div v-if="isInProcessing" class="text-caption">
      {{ $t('main.campaign_actions.in_processing') }}
    </div>
  </div>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import routeNames from '@/types/route-names.js'

  export default {
    name: 'CampaignActions',
    components: {
      CBtn
    },
    props: {
      campaign: {
        type: Object,
        default: () => ({})
      },
      icons: {
        type: Object,
        default: () => ({
          activate: '$play-outlined',
          pause: '$pause',
          statistics: '$chart-line',
          edit: '$pencil',
          duplicate: '$copy',
          delete: '$trash',
          download: 'mdi-download'
        })
      },
      colorIcons: {
        type: Object,
        default: () => ({
          activate: 'primary',
          pause: 'warning',
          statistics: 'info',
          edit: 'black',
          duplicate: 'black',
          delete: 'error',
          download: 'info'
        })
      }
    },
    computed: {
      actions() {
        return [
          {
            color: this.colorIcons.download,
            eventName: 'download',
            icon: this.icons.download,
            title: this.$t('main.campaign_actions.download_io'),
            isShown: !!this.campaign.flatDealApplication?.id
          },
          {
            color: this.colorIcons.activate,
            eventName: 'activate',
            icon: this.icons.activate,
            title: this.$t('main.campaign_actions.activate_campaign'),
            isShown: this.campaign.actions.activable
          },
          {
            condition: 'pausable',
            color: this.colorIcons.pause,
            eventName: 'pause',
            icon: this.icons.pause,
            title: this.$t('main.campaign_actions.pause_campaign'),
            isShown: this.campaign.actions.pausable
          },
          {
            disabled: !this.campaign.actions.editable,
            color: this.colorIcons.edit,
            eventName: 'edit',
            icon: this.icons.edit,
            title: this.$t('main.campaign_actions.edit_campaign')
          },
          {
            color: this.colorIcons.statistics,
            action: 'statistics',
            eventName: 'statistics',
            icon: this.icons.statistics,
            title: this.$t('main.campaign_actions.show_statistics')
          },
          {
            disabled: !this.campaign.actions.duplicable,
            color: this.colorIcons.duplicate,
            eventName: 'duplicate',
            icon: this.icons.duplicate,
            title: this.$t('main.campaign_actions.duplicate_campaign')
          },
          {
            disabled: !this.campaign.actions.deletable,
            color: this.colorIcons.delete,
            eventName: 'archive',
            icon: this.icons.delete,
            title: this.$t('main.campaign_actions.archive_campaign')
          }
        ].filter((action) => action.isShown !== false)
      },
      isInProcessing() {
        return this.campaign.next_status || this.campaign.nextStatus
      }
    },
    methods: {
      act(action) {
        if (action.eventName) {
          this.$emit(action.eventName)
        }

        if (action.action) {
          this[action.action]()
        }
      },
      statistics() {
        this.$router.push({
          name: routeNames.CAMPAIGN_STATISTICS,
          query: { campaigns: JSON.stringify([this.campaign.id]) }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .action:not(:last-of-type){
    border-right: 1px solid var(--v-secondary-base) !important;
  }
</style>
