<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <v-icon
        class="global-cursor-pointer ml-1"
        color="black"
        size="16"
        v-bind="attrs"
        v-on="on"
      >
        $billings
      </v-icon>
    </template>
    <span class="text-caption white--text">
      {{ campaign.campaign_group && campaign.campaign_group.name }}
    </span>
  </v-tooltip>
</template>

<script>
  export default {
    name: 'CampaignGroupTooltip',
    props: {
      campaign: {
        type: Object,
        default: () => ({})
      }
    }
  }
</script>
