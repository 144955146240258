<template>
  <div class="d-flex flex-column flex-sm-row">
    <div v-if="isMobile">
      <c-select
        :class="[{ 'global-full-width': isMobile }]"
        :value="filters.status"
        :items="computedStatuses"
        :loading="isLoading"
        :placeholder="$t('campaign.search_status')"
        item-text="label"
        item-value="value"
        border-color="secondary-lighten"
        :height="isMobile ? 46 : 30"
        background-color="white"
        clearable
        hide-details
        @change="setFilters('status', $event)"
      />
    </div>
    <div v-else class="d-flex">
      <c-toggle-buttons
        :value="filters.status"
        :buttons="computedStatuses"
        mandatory
        item-text="label"
        class="only-icon mr-2"
        :button-height="30"
        :button-attrs="{
          color: 'transparent primary--text',
          class: 'text-caption d-flex align-center justify-center ma-0'
        }"
        @change="setFilters('status', $event)"
      />
    </div>
    <c-select
      :class="[{ 'global-full-width': isMobile }]"
      :value="filters.limits"
      :items="computedLimits"
      :loading="isLoading"
      item-text="label"
      item-value="value"
      border-color="secondary-lighten"
      :height="isMobile ? 46 : 30"
      background-color="white"
      class="campaign-select mt-2 mr-sm-2 mt-sm-0"
      hide-details
      @change="setFilters('limits', $event)"
    />
  </div>
</template>

<script>
  import CToggleButtons from '@clickadilla/components/ui/CToggleButtons.vue'
  import { mapState } from 'vuex'
  import CSelect from '@clickadilla/components/ui/CSelect.vue'
  import { GTM_EVENTS } from '@clickadilla/components/constants/gtm-events.js'
  import gtmPush from '@/services/utils/gtm-push.js'

  export default {
    name: 'CampaignNewFilters',
    components: { CSelect, CToggleButtons },
    props: {
      filters: {
        type: Object,
        required: true
      },
      isLoading: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      ...mapState('campaigns', ['statuses', 'limitStatuses']),
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      computedStatuses() {
        const statuses = this.statuses.map((item) => ({ ...item, label: this.isMobile ? this.$t(item.label) : null }))

        return [
          {
            id: statuses.length + 1,
            value: null,
            label: this.isMobile ? this.$t('campaign.all_statuses') : this.$t('campaign.all')
          },
          ...statuses
        ]
      },
      computedLimits() {
        const limits = this.limitStatuses.map((item) => ({ ...item, label: this.$t(item.label) }))

        return [
          {
            id: limits.length + 1,
            value: null,
            label: this.$t('campaign.limits_statuses.all_limits')
          },
          ...limits
        ]
      }
    },
    methods: {
      setFilters(name, event) {
        this.$emit('set-filters', { name, event })
        gtmPush({
          event: GTM_EVENTS.CAMPAIGNS_FILTERS,
          name: event || 'all'
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep .only-icon .v-icon{
    margin: 0;
  }
  .campaign-select ::v-deep .v-select__selections input{
    display: none;
  }

  .campaign-select ::v-deep .v-select__selection{
    overflow: unset;
    text-overflow: unset;
  }

</style>
