<template>
  <c-select
    v-bind="$attrs"
    :value="value"
    :items="campaignGroups"
    :loading="isLoading"
    :placeholder="$t('main.campaign_group')"
    item-text="name"
    item-value="id"
    :label="label"
    :label-bold="labelBold"
    :background-color="backgroundColor"
    :border-color="borderColor"
    clearable
    hide-details
    @change="$emit('input', $event)"
  />
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import CSelect from '@clickadilla/components/ui/CSelect.vue'

  export default {
    name: 'CampaignGroupSelect',
    components: {
      CSelect
    },
    props: {
      value: {
        validator: (prop) => [null, undefined].includes(prop) || typeof prop === 'number',
        required: true
      },
      errorMessages: {
        type: Array,
        default: () => []
      },
      isLoading: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        default: ''
      },
      labelBold: {
        type: Boolean,
        default: false
      },
      backgroundColor: {
        type: String,
        default: ''
      },
      borderColor: {
        type: String,
        default: ''
      }
    },
    computed: {
      ...mapState('campaigns', ['campaignGroups', 'groupId'])
    },
    watch: {
      groupId(newValue) {
        if (!newValue) return

        this.$emit('input', newValue)
      }
    },
    created() {
      this.fetchCampaignGroups()
    },
    methods: {
      ...mapActions('campaigns', ['fetchCampaignGroups'])
    }
  }
</script>
