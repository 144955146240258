<template>
  <div>
    <campaigns-table />
    <campaign-groups-table class="mt-7" />
  </div>
</template>

<script>
  import CampaignsTable from '@/views/Campaigns/CampaignsTable.vue'
  import CampaignGroupsTable from '@/views/Campaigns/CampaignGroupsTable/Index.vue'

  export default {
    name: 'Campaigns',
    components: {
      CampaignsTable,
      CampaignGroupsTable
    }
  }
</script>
