<template>
  <div class="d-flex align-center">
    <custom-chip-status
      small
      label
      text-color="black"
      with-icon
      :value="iconByStatus.label"
      :icon-props="iconByStatus"
      :settings="statuses"
    />
    <v-tooltip v-if="hasLimitStatus" bottom>
      <template #activator="{ on, attrs }">
        <v-icon v-bind="attrs" :size="12" class="ml-2" color="warning" v-on="on">
          $blocked
        </v-icon>
      </template>
      {{ limitStatus }}
    </v-tooltip>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import CustomChipStatus from '@/components/CustomChipStatus.vue'

  export default {
    name: 'CampaignNewStatus',
    components: { CustomChipStatus },
    props: {
      campaign: {
        type: Object,
        default: () => ({})
      }
    },
    computed: {
      ...mapState('campaigns', ['statuses']),
      hasLimitStatus() {
        return !!this.campaign.limit_exceeded_name
      },
      limitStatus() {
        if (!this.hasLimitStatus) {
          return ''
        }
        return this.$t(`main.limit_statuses.${this.campaign.limit_exceeded_name}`)
      },
      iconByStatus() {
        const status = this.statuses.find(({ value }) => value === this.campaign.status)
        return { ...status, label: this.$t(status.label) }
      }
    }
  }
</script>
