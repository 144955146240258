<template>
  <v-dialog max-width="600" :value="true" @input="close()">
    <v-card>
      <v-card-title> {{ $t(title) }} </v-card-title>

      <v-card-text>
        <slot />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <c-btn text color="primary" :label="$t('main.cancel')" @click="close()" />
        <c-btn
          color="primary"
          :label="textField ? $t('main.save') : $t('main.yes')"
          @click="$emit(campaignConfigForm.eventName)"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'

  export default {
    name: 'CampaignDialog',
    components: {
      CBtn
    },
    props: {
      campaignConfigForm: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        title: '',
        message: '',
        selectField: false,
        textField: false,
        payload: { name: '' }
      }
    },
    created() {
      this.title = this.campaignConfigForm.title || ''
      this.message = this.campaignConfigForm.message || ''
      this.selectField = this.campaignConfigForm.selectField || false
      this.textField = this.campaignConfigForm.textField || false
      this.payload = this.campaignConfigForm.payload || { name: '' }
    },
    methods: {
      close() {
        this.$emit('close-dialog')
      }
    }
  }
</script>
