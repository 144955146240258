<template>
  <div v-if="!isEditing" class="d-flex flex-wrap">
    <v-chip
      v-for="(filter) in activeFilters"
      :key="filter.key"
      class="mr-2 mb-2 chip"
      color="secondary-lighten"
      text-color="black"
      :large="isMobile"
      @click:close="$emit('remove', filter.key)"
      @click="editingFilter(filter.key)"
    >
      {{ $t(filter.name) }}: {{ $t(filter.value.label) || filter.value.name }}
      <v-avatar right>
        <c-close-btn class="ml-2" color="transparent" width="16" height="32" icon-class="error--text" @click="$emit('remove', filter.key)" />
      </v-avatar>
    </v-chip>
    <c-btn
      v-if="canAddingFilter && !showFormFilter"
      :class="[{ 'my-auto pb-1': isMobile }]"
      color="primary"
      icon
      :width="12"
      :height="isMobile ? 12 : 32"
      :icon-props="{ icon: ' $plus', size: 12 }"
      @click="$emit('add')"
    />
  </div>
  <div v-else>
    <campaign-filters-form
      :is-loading="isLoading"
      :filter-list="computedSelectableFilters"
      :is-editing="isEditing"
      :editing-item="editingItem"
      @set-filters="setFilters($event)"
    />
  </div>
</template>

<script>
  import CCloseBtn from '@clickadilla/components/ui/CCloseBtn.vue'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import CampaignFiltersForm from '@/components/Campaign/CampaignFilters/CampaignFiltersForm.vue'

  export default {
    name: 'SelectedCampaignFilters',
    components: { CampaignFiltersForm, CBtn, CCloseBtn },
    props: {
      activeFilters: {
        type: Array,
        required: true
      },
      filterList: {
        type: Array,
        required: true
      },
      isLoading: {
        type: Boolean,
        required: true
      },
      showFormFilter: {
        type: Boolean,
        default: false
      },
      canAddingFilter: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        isEditing: false,
        editingKey: '',
        editingItem: null
      }
    },
    computed: {
      computedSelectableFilters() {
        const foundFilter = this.filterList.find((filterItem) => filterItem.key === this.editingKey)
        return [foundFilter]
      },
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      }
    },
    methods: {
      editingFilter(key) {
        this.editingKey = key
        this.isEditing = true
        const foundFilter = this.filterList.find((filterItem) => filterItem.key === this.editingKey)
        this.setEditingFilterItem(foundFilter)
        this.$emit('edit-filter', true)
      },
      setFilters(data) {
        this.isEditing = false
        this.editingItem = null
        this.$emit('set-filters', data)
        this.$emit('edit-filter', false)
      },
      setEditingFilterItem(data) {
        this.editingItem = data
      }
    }
  }
</script>

<style lang="scss" scoped>
  .chip ::v-deep .v-chip__content{
    text-wrap: wrap;
  }
</style>
